'use client';
import { FlexContainer } from "@/uikit/container";
import { Block } from "../base";
import { BlockContainer } from "../base/block-container";
import React from "react";
import { Typography } from "@/uikit/Typography";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination } from 'swiper/modules';
import IconStar from '../../svg/icons/iconStar.svg'

import { buttonWrpper, manipulationConteinerStyles, manipulationWrapperStyle, sliderBulletActiveStyle, sliderBulletConteinerStyle, sliderBulletStyle, sliderContainer, subltitles, subltitlesWrapper, textAlignCenter, titleColor, titleContainer } from "./sliderBlock.css";
import SlideComponent from "./SliderComponent";
import SlideButton from "./SliderButton";
import { SLIDES_PER_VIEV, SLIDES_SPACE_BETWEEN } from "@/uikit/variables";



const SliderBlock = Block("sliderBlock", ({ title, subtitle, carousel }) => {
    const renderStars = () => {
        const stars = [];

        if (subtitle && typeof subtitle.stars === 'number') {
            for (let i = 0; i < subtitle.stars; i++) {
                stars.push(<IconStar key={i} />);
            }
        }

        return stars;
    };
    return (
        <BlockContainer id="testimonials" blockPaddingTop="medium" className={sliderContainer}>
            <FlexContainer alignItems="center" justifyContent="center">
                <div className={titleContainer}>
                    <Typography settings={{ tag: "h2" }} className={textAlignCenter}>
                        {title && title.map((part: any, index: any) => (
                            <React.Fragment key={part._key}>
                                <span className={titleColor({ color: part.color })}>
                                    {part.text}
                                </span>
                                {index < title.length - 1 && ' '}
                            </React.Fragment>
                        ))}
                    </Typography>
                    {/* {subtitle && (
                        <div className={subltitlesWrapper}>
                            <span className={subltitles({ fontStyle: 'normal' })}>{subtitle?.rated}</span>
                            {renderStars()}
                            <span className={subltitles({ fontStyle: 'normal' })}>{subtitle?.by}</span>
                            <span className={subltitles({ fontStyle: 'bold' })}>{subtitle.reviews}</span>
                            <span className={subltitles({ fontStyle: 'normal' })}>{subtitle.text}</span>
                        </div>
                    )} */}
                </div>
            </FlexContainer>
            {carousel && (
                <FlexContainer padding="slider">
                    <Swiper
                        slidesPerView={SLIDES_PER_VIEV}
                        spaceBetween={SLIDES_SPACE_BETWEEN}
                        centeredSlides
                        grabCursor
                        loop
                        pagination={{
                            clickable: true,
                            horizontalClass: sliderBulletConteinerStyle,
                            bulletClass: sliderBulletStyle,
                            bulletActiveClass: sliderBulletActiveStyle,
                            el: '.pagination',
                        }}
                        modules={[Pagination, Navigation]}
                        navigation={{
                            nextEl: '.nextSlide',
                            prevEl: '.prevSlide',
                        }}
                        loopAdditionalSlides={1}
                        breakpoints={{
                            300: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                                grabCursor: false,
                            },

                            768: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                                grabCursor: false,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 500,
                                grabCursor: false,
                            },
                            1161: {
                                slidesPerView: SLIDES_PER_VIEV,
                                spaceBetween: SLIDES_SPACE_BETWEEN,
                                grabCursor: true,
                            },
                        }}

                    >
                        {carousel?.map((item: any, index: number) => (
                            <SwiperSlide key={item._key || index}>
                                {({ isActive }) => (
                                    <SlideComponent
                                        item={item}
                                        isActive={isActive}
                                    />
                                )}
                            </SwiperSlide>
                        ))}
                        <div className={manipulationWrapperStyle}>
                            <div className={manipulationConteinerStyles}>
                                <div className={buttonWrpper}>
                                    <SlideButton direction={'prev'} className={'prevSlide'} />
                                    <SlideButton direction={'next'} className={'nextSlide'} />
                                </div>
                                <div className="pagination" />
                            </div>
                        </div>
                    </Swiper>

                </FlexContainer>
            )}
        </BlockContainer>
    )
})
export default SliderBlock;