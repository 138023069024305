import { clsx } from "@/uikit/utils";
import { arrowNextStyle, arrowPrevStyle, nextButton, prevButton, sliderButton, } from "./sliderButton.css";
import ArrowIcon from '../../../svg/icons/iconArrowLeft.svg'

type Direction = 'next' | 'prev';

interface SlideButtonProps {
    direction: Direction;
    className: string;
}

const SlideButton: React.FC<SlideButtonProps> = ({ direction, className }) => {
    const buttonClass = clsx(className, sliderButton);
    const prevButtonClass = clsx(buttonClass, prevButton);
    const nextButtonClass = clsx(buttonClass, nextButton);

    const buttonClassName = direction === 'prev' ? prevButtonClass : nextButtonClass;
    const arrowClassName = direction === 'prev' ? arrowPrevStyle : arrowNextStyle;

    return (
        <button
            type="button"
            className={buttonClassName}
        >
            <ArrowIcon className={arrowClassName} />
        </button>
    );
};

export default SlideButton;