import { Typography } from "@/uikit/Typography";
import { clsx } from "@/uikit/utils";
import { authorText, authorWrapper, avatar, slideWrapper, sliderCard, sliderNotActiveStyle, startWrapper } from "./sliderComponent.css";
import IconStar from '../../../svg/icons/iconStar.svg'

import { Key } from "react";
import { RemoteImage } from "@/uikit/remote-image";
import { STAR_COUNT } from "@/uikit/variables";

export interface Slide {
    _key: Key | null | undefined;
    slideText?: string;
    avatar?: any;
    author?: string;
    authorTitle?: string;

}

interface SlideComponentProps {
    item: Slide;
    isActive: boolean;
}

const SlideComponent: React.FC<SlideComponentProps> = ({
    item,
    isActive,
}) => {
    const handleSlidePointerEvents = (isActiveProps: boolean) => {
        return isActiveProps ? 'all' : 'none';
    };

    const renderStars = () => {
        const stars = [];

        for (let i = 0; i < STAR_COUNT; i++) {
            stars.push(<IconStar key={i} />);
        }

        return stars;
    };
    return (
        <div
            className={slideWrapper}
        >
            <div
                style={{
                    pointerEvents: handleSlidePointerEvents(isActive),
                }}
                className={clsx(
                    sliderCard,
                    isActive ? '' : sliderNotActiveStyle
                )}>
                <div className={startWrapper}>
                    {renderStars()}
                </div>
                <Typography
                    settings={{ tag: 'slideText' }}
                >
                    {item.slideText}
                </Typography>
                <div className={authorWrapper}>
                    {item.avatar && (
                        <RemoteImage
                            image={item.avatar}
                            className={avatar}
                        />
                    )}
                    {item.author && item.avatar && (
                        <div>
                            <span className={authorText({ weight: 'bold' })}>{item.author}</span>
                            <span className={authorText({ weight: 'normal' })}>{item.authorTitle}</span>
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
};

export default SlideComponent;