import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fblocks%2FSliderBlock%2FsliderBlock.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA51TXY%2BbMBB8z6%2FY6lSplerIkE%2B4l56q9H8Y2BBfwUb2UnKt8t8rDMlBcHJ3fQHMjmfHs%2BP5sz6kEQXwdwaQSVsV4iWGfYHHxxm4N8ukwZSkVjGkuqhL1VZKcWSNzOgQw3rLK4fORRXDctUuTrN5Rxw6YsIjMVHIvOVARWgGkKWDpLrQJoYHzvmgthrVgqfN5mc4KK9dudcRcP65lXFAmR8ohnAoa9t9T07oRDFJWNpXaQDPtSW5f2GpVoSKPKo3o9ZhR39ufV4n2mRomBGZrG2rsP8t0l%2B50bXKYnjYRbun3Y%2F2NxmhrOycdsTA56EFFBaZrgm4%2FTbYOikOxG3%2FR1zoETeeRuRoS2FyqWLgIGrSg7KYTuNSS1ytElkmVc5IVzEs%2BCgp6fsiaHRzd0A3R9qlc9QymyYPYK8VMSv%2FYAzhsrOkkArZxb7xwdBxuE1Nj1iOTNtPAdsRIPeFeHC%2FIt7HptLndBgsBMnf%2BDgYx2UYtpEVmpHfiSbSZXcl4JMsK21IKGrh30vMpIAvg4YBDxfV8atj6DVytxjJOit97SJVa9RrzE9%2B%2Bs16c8Ue9Oy3iS5QMRWy7XTc6LZYRVfdPBS1skgfIkl6kklebybWk1lbiRRZgtQgqg5zI7zvtjK5stLdtPXq7kA8836D5oJL7%2BXCGdGY9t61z3sKguja4Nwz6PB%2BsDzn8LCs%2BUfj6SE5H%2FI0O%2F0D4SkS00IHAAA%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var buttonWrpper = 'johc9tg';
export var centerAlignmentStyle = 'johc9t9';
export var manipulationConteinerStyles = 'johc9tb';
export var manipulationWrapperStyle = 'johc9ta johc9t9';
export var sliderBulletActiveStyle = 'johc9t8';
export var sliderBulletConteinerStyle = 'johc9t6';
export var sliderBulletStyle = 'johc9t7';
export var sliderContainer = 'johc9t0';
export var subltitles = _7a468({defaultClassName:'johc9td',variantClassNames:{fontStyle:{normal:'johc9te',bold:'johc9tf'}},defaultVariants:{},compoundVariants:[]});
export var subltitlesWrapper = 'johc9tc';
export var textAlignCenter = 'johc9t2';
export var titleColor = _7a468({defaultClassName:'johc9t3',variantClassNames:{color:{black:'johc9t4',blue:'johc9t5'}},defaultVariants:{},compoundVariants:[]});
export var titleContainer = 'johc9t1';